import React from 'react'
import { graphql } from 'gatsby'
import Img from "gatsby-image";
import { theme } from "../styles";
import styled from '@emotion/styled'
import { Wrapper, Header } from '../components'
import website from '../../config/website'
import SEO from '../components/SEO'

const IndexWrapper = Wrapper.withComponent('main')
const Inner = styled.div`
  .gatsby-image-wrapper {
    max-width: 100% !important;
  }
  img {
    max-width: 100% !important;
    display: block;
  }
  p {
    margin: 1rem auto;
    font-family: "Lato" !important;
  }
  h2 {
    font-family: "Lato" !important;
    font-size: 2.4rem;
    margin: 0 0 0.5rem 0;
    font-weight: 500;
    color: #6d6d6d;
    text-transform: uppercase;
  }
  h3 {
    font-family: "Lato" !important;
    font-size: 1.4rem;
    margin: 0 0 2rem 0;
    font-weight: 300;
    color: #6d6d6d;
    text-transform: uppercase;
    span {
      font-weight: 500;
    }
    &.h2 {
      font-size: 1.8rem!important;
    }
  }
`;

const Head = styled.div`
  position: relative;
  margin-bottom: 1.35rem;
`;

const Hero = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  > div {
    width: 50%;
    &:first-of-type {
      padding-right: 1rem;
    }
    &:last-of-type {
      padding-left: 1rem;
    }
    p {
      margin-bottom: 1.35rem;
      font-size: 1.1rem;
      color: #6d6d6d;
    }
  }
  @media (max-width: ${theme.breakpoints.s}) {
    display: block;
    > div {
      width: 100%;
      &:first-of-type {
        padding-right: 0;
      }
      &:last-of-type {
        padding-left: 0;
      }
      p {
        &:first-of-type {
          padding-top: 1.35rem;
        }
      }
    }
  }
`;

const Banner = styled.div`
  position:relative;
  margin-bottom: 2rem;
`;

const Gallery = styled.div`
  position: relative;
`;

const Murmures = ({ pageContext: { locale }, location, data: {images} }) => {
  const context = { slug: "exhibition", parent: "" };
  const header = images.edges.filter((i) => i.node.relativePath === "murmures/header.png")[0];
  const hero = images.edges.filter((i) => i.node.relativePath === "murmures/hero.png")[0];
  const banner = images.edges.filter((i) => i.node.relativePath === "murmures/banner.png")[0];
  const gallery = images.edges.filter((i) => i.node.relativePath === "murmures/gallery.png")[0];
  const footer = images.edges.filter((i) => i.node.relativePath === "murmures/footer.png")[0];
  
  return (
    <React.Fragment>
      <SEO
        pathname={location.pathname}
        locale={locale}
        title={`Disch Rémy - Murmures`}
        desc={`Exposition "Murmures" du 3 au 8 septembre 2020, galerie Bansard.`}
      />
      <Header {...context} />
      <IndexWrapper id={website.skipNavId}>
        <Inner>
          <Head>
            <img src={header.node.publicURL} />
            {/* <Img
              fixed={header.node.childImageSharp.fixed}
              placeholderStyle={{
                filter: "grayscale(100%) contrast(100%)",
                imageRendering: "pixelated",
                opacity: 0,
              }}
            /> */}
          </Head>
          <Hero>
            <div>
              <h2>
                <span>Murmures</span>
              </h2>
              <h3>
                Galerie <strong>Bansard</strong> <span>- Paris</span>
                <br />
                <span>3_8</span> septembre 2020
              </h3>
              <img src={hero.node.publicURL} />
              {/* <Img
                fixed={hero.node.publicURL}
                placeholderStyle={{
                  filter: "grayscale(100%) contrast(100%)",
                  imageRendering: "pixelated",
                  opacity: 0,
                }}
              /> */}
            </div>

            <div>
              <p style={{ marginTop: 0 }}>
                Comme les traces d’une présence révolue, discrète et inspirante,
                qui dans chacun de mes gestes se retrouve et se perd,
                l’exposition Murmures fait se raviver les parfums, les sons et
                les visages dont notre être se souvient.
              </p>
              <p>
                Habité par cette absence qui hante et nourrit mon âme, me
                murmure les résurgences d’un amour non partagé, les mouvements
                d’une silhouette douce et légère qui l’ont menée ailleurs, hors
                de regard et de peau, loin de ces moments pudiques et fiévreux,
                je plonge dans le vide. Mon corps tout entier s’y est engouffré.
              </p>
              <p>
                Pas de peine ni de fatalité, mais la vie tout simplement: son
                mouvement, sa résonance, ce que nous laissons ou reprenons à
                ceux croisant notre route, ces amours profonds et
                déstabilisants, ce que nous sommes et devenons.
              </p>
              <p>
                Tout cela, je l’accueille ; ces doutes, ces amours et ces
                espoirs, qui, partagés ou non, qu’ils durent ou qu’ils
                s’effacent, engendrent une douleur tendre, compagne versatile,
                qui m’inspire et murmure au fond de moi.
              </p>
              <p>
                Apaisé maintenant, je continue ma chute vertigineuse et
                incontrôlée vers un endroit où chacun de mes dialogues, de mes
                mots et de mes pensées, sont tout entiers dédiés à ces yeux qui
                me fixent, ces contours qui s’estompent et immortalisent ces
                murmures à présent extraits de moi.
              </p>
              <p>
                Humble et à jamais convaincu que l’amour, même perdu, est une
                élévation de l’âme, voilà ce qu’aujourd’hui je partage avec
                vous.
              </p>
            </div>
          </Hero>
          <Banner>
            <img src={banner.node.publicURL} />
            {/* <Img
              fixed={banner.node.publicURL}
              placeholderStyle={{
                filter: "grayscale(100%) contrast(100%)",
                imageRendering: "pixelated",
                opacity: 0,
              }}
            /> */}
          </Banner>
          <Hero>
            <div>
              <h3 style={{ marginBottom: 0 }} className="h2">
                <strong>Silences _</strong> <span>David Braud</span>
              </h3>
              <p style={{ marginTop: 10, paddingTop: 0 }}>
                <strong>Silences</strong> met en scène les tourments et la
                beauté de l'être humain, l'introspection, le travail et le
                doute, constantes cachées derrière le trait et le mouvement.
                C'est un hommage à l'humilité et à la douceur, à tous ces
                silences créateurs.
              </p>
            </div>
            <div>
              <iframe
                width="100%"
                height="180"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/886880206&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
              ></iframe>
            </div>
          </Hero>
          <Gallery>
            <img src={gallery.node.publicURL} />
            {/* <Img
              fluid={gallery.node.childImageSharp.fluid}
              placeholderStyle={{
                filter: "grayscale(100%) contrast(100%)",
                imageRendering: "pixelated",
                opacity: 0,
              }}
            /> */}
          </Gallery>
          <div>
            <img src={footer.node.publicURL} alt="remerciements" />
          </div>
        </Inner>
      </IndexWrapper>
    </React.Fragment>
  );
};

export default Murmures;

export const pageQuery = graphql`
  query MurmuresQuery {
    images: allFile(filter: {relativeDirectory: {eq: "murmures"}}) {
      edges {
        node {
          absolutePath
          relativeDirectory
          relativePath
          publicURL
        }
      }
    }
  }
`;
